import * as React from "react";
import { FunctionComponent } from "react";
import { Context } from "../../context/context";
import { BoardViewModel, PitViewModel } from "@mancala/core";
import PitView from "./PitView";
import StoreView from "./StoreView";
import { Game } from "@mancala/core";
import { Pit } from "mancala.js";

const BoardView: FunctionComponent<{
  game: Game;
  context: Context;
  boardId: string;
  boardViewModel: BoardViewModel;
  revert: boolean;
  onPitSelect: (index: number, pit: Pit) => void;
}> = ({ game, context, boardId, boardViewModel, revert, onPitSelect: onPitSelect }) => {
  const mancalaGame = game?.mancalaGame;
  const theme = context.themeManager.theme;

  const createPitView = (key: any, pit: Pit, pitViewModel: PitViewModel) => {
    return <PitView key={key} pitViewModel={pitViewModel} onClick={() => onPitSelect(pit.index, pit)} />;
  };
  const createPitViewList = (pits: Pit[]) => pits.map((pit, index) => createPitView(index, pit, boardViewModel.pits[pit.index]));

  const player1Pits = createPitViewList(mancalaGame?.board.player1Pits);
  const player2Pits = createPitViewList(mancalaGame?.board.player2Pits);
  const player1BankIndex = mancalaGame?.board.player1BankIndex();
  const player2BankIndex = mancalaGame?.board.player2BankIndex();
  const player1BankViewModel = boardViewModel.pits[player1BankIndex];
  const player2BankViewModel = boardViewModel.pits[player2BankIndex];
  return (
    <div className="board" style={{ background: theme.boardColor }}>
      <StoreView
        context={context}
        pitViewModel={revert ? player2BankViewModel : player1BankViewModel}
        gridColumn="8 / 9"
        gridRow="1 / 3"
      />
      <StoreView
        context={context}
        pitViewModel={revert ? player1BankViewModel : player2BankViewModel}
        gridColumn="1 / 2"
        gridRow="1 / 3"
      />
      {revert ? player1Pits?.reverse() : player2Pits?.reverse()}
      {revert ? player2Pits : player1Pits}
      <style jsx>{`
        .board {
          padding: 2vw;
          display: grid;
          grid-template-columns: repeat(8, 11vw);
          grid-template-rows: repeat(2, 11vw);
          border-radius: 3vw;
          transition: background-color 0.5s;
        }
      `}</style>
    </div>
  );
};

export default BoardView;
