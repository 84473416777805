
export function getBrightness(r: number, g: number, b: number) {
  return (r * 299 + g * 587 + b * 114) / 1000;
}

export function getBrightnessFromHexColor(hexColor: string): number {
  const [r, g, b] = hexToRgb(hexColor);
  return getBrightness(r, g, b);
}

// from https://www.codegrepper.com/code-examples/javascript/javascript+convert+color+string+to+rgb
export function rgbToHex(r: number, g: number, b: number): string {
  return "#" + ((1 << 24) + (r << 16) + (g << 8) + b).toString(16).slice(1);
}

// from https://www.codegrepper.com/code-examples/javascript/javascript+convert+color+string+to+rgb
export function hexToRgb(
  hex: string,
  result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex)
): number[] {
  // TODO: fix later
  //@ts-ignore
  return result ? result.map((i) => parseInt(i, 16)).slice(1) : null;
  //returns [23, 14, 45] -> reformat if needed
}

export function getColorByBrightness(
  color: string,
  lightColor: string,
  darkColor: string
): string {
  return getBrightnessFromHexColor(color) < 125 ? darkColor : lightColor;
}
