import lightTheme from "./LightTheme";
import greyTheme from "./GreyTheme";
import { Theme } from "./Theme";
import darkTheme from "./DarkTheme";
import { TinyEmitter } from "tiny-emitter";
import { Storage  } from "../storage/storage";

export const themes = [lightTheme, darkTheme, greyTheme];

const THEME_ID = "theme_id";

export type ThemeManagerEvents = "themechange";

export class ThemeManager extends TinyEmitter {
  private _theme: Theme;
  private _storage: any;
  constructor(storage: Storage) {
    super();
    this._storage = storage;
    this._theme = this.readFromLocalStorage() || lightTheme;
  }

  public get theme() {
    return this._theme;
  }

  public set theme(value: Theme) {
    this._theme = value;
    this.emit("themechange", value);
    this.writetToLocalStorage(value);
  }

  private writetToLocalStorage(value: Theme) {
    this._storage.set(THEME_ID, value.id);
  }

  private readFromLocalStorage(): Theme | undefined {
    const themeID = this._storage.getString(THEME_ID);
    const theme = themes.find((eachTheme: Theme) => themeID === eachTheme.id);
    return theme;
  }

  public get themes(): Theme[] {
    return themes;
  }

  public on(event: ThemeManagerEvents, callback: (...value: any[]) => void): this {
    return super.on(event, callback);
  }
  public off(event: ThemeManagerEvents, callback: (...value: any[]) => void): this {
    return super.off(event, callback);
  }
}
