import * as React from "react";
import { FunctionComponent } from "react";
import { Context } from "../../context/context";
import { getColorByBrightness } from "@mancala/core";
import Util from "../../util/Util";
import { PitViewModel } from "@mancala/core";
import StoneView from "./StoneView";

const StoreView: FunctionComponent<{
  context: Context;
  pitViewModel: PitViewModel;
  gridColumn: string;
  gridRow: string;
}> = ({ context, pitViewModel, gridColumn, gridRow }) => {
  const stones = [...Util.range(pitViewModel.stoneCount)].map((i, index) => (
    <StoneView key={index} color={pitViewModel.stoneColor} />
  ));
  const textColor = getColorByBrightness(
    pitViewModel.pitColor,
    context.themeManager.theme.textColor,
    context.themeManager.theme.textLightColor
  );
  return (
    <div
      className="store"
      style={{
        background: pitViewModel.pitColor,
        gridColumn: gridColumn,
        gridRow: gridRow
      }}>
      {stones}
      <span className="store-stone-count-text" style={{ color: textColor, transition: 'color 0.5s' }}>
        {stones.length}
      </span>
      <style jsx>{`
          .store {
            margin: 5px;
            border-radius: 10vw;
            display: flex;
            align-items: center;
            justify-content: center;
            align-content: center;
            flex-wrap: wrap;
            position: relative;
            transition: background-color 0.5s;
          }
          .store-stone-count-text {
            position: absolute;
            bottom: 2vw;
            font-family: monospace;
            font-weight: bold;
            font-size: 2vw;
          }
        `}</style>
    </div>
  );
};

export default StoreView;