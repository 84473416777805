import * as React from 'react';
import { FunctionComponent } from 'react';

const HeaderbarTitle: FunctionComponent<{ title: string, color: string }> = ({ title, color }) => {
    return (
        <h1 style={{ color: color, transition: 'color 0.5s' }} className="header-bar-title">
            {title}
            <style jsx>{`
            .header-bar-title {
                margin: 10px 0px;
            }
            `}</style>
        </h1>
    );
}

export default HeaderbarTitle;