import * as React from 'react';
import { FunctionComponent } from 'react';

const Center: FunctionComponent = ({children}) => {
    return (
        <div className='center'>
            {children}
            <style jsx>{`
                .center {
                    display: flex;
                    height: 100%;
                    width: 100%;
                    align-items: center;
                    justify-content: center;
                }
            `}</style>
        </div>
    );
}

export default Center;