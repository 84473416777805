export const isAlpha = true;

export type Server = {
    serverAdress: string;
    wsServerAdress: string;
};

export const LOCAL_PORT='localhost:5005';

export const server: Server = {
    //@ts-ignore
    serverAdress: process.env.SERVER_ADDRESS ? `https://${process.env.SERVER_ADDRESS}` : 'http://' + LOCAL_PORT,
    //@ts-ignore
    wsServerAdress: process.env.SERVER_ADDRESS ? `wss://${process.env.SERVER_ADDRESS}` : 'ws://' + LOCAL_PORT,
}

export const RTMT_WS_PING_INTERVAL = 1000, RTMT_WS_PING_INTERVAL_BUFFER_TIME = 2000;