import * as React from "react";
import { FunctionComponent } from "react";
import { Context } from "../context/context";
import { Game, User } from "@mancala/core";
import { getColorByBrightness } from "@mancala/core";
import CircularPanel from "./CircularPanel";
import { useTranslation } from "react-i18next";

function getInfoPanelTextByGameState(params: {
  context: Context;
  t: (text: string) => string;
  game?: Game;
  currentUser: User;
  whitePlayer: User;
  blackPlayer: User;
  leftPlayer?: User;
  isSpectator?: boolean;
}): string | undefined {
  const {
    context,
    t,
    game,
    currentUser,
    whitePlayer,
    blackPlayer,
    leftPlayer,
    isSpectator
  } = params;

  if (leftPlayer) {
    return isSpectator ? `${leftPlayer.name} ${t("UserLeftTheGame")}` :
      leftPlayer.id == currentUser.id ? t("YouLeftTheGame") : t("OpponentLeftTheGame");
  }

  const isGameEnded = game?.mancalaGame.state == "ended";
  if (isGameEnded) {
    const wonPlayerID = game.mancalaGame.getWonPlayerId();
    let whoWon;
    if (wonPlayerID) {
      const wonPlayer = wonPlayerID == whitePlayer.id ? whitePlayer : blackPlayer;
      whoWon = isSpectator ? `${wonPlayer.name} ${t("Won")}` :
        game.mancalaGame.getWonPlayerId() === currentUser.id
          ? t("YouWon")
          : t("YouLost");
    } else {
      whoWon = t("GameDraw");
    }
    return t("GameEnded") + " " + whoWon;
  }

  if (game) {
    const playingPlayer = game.mancalaGame.checkIsPlayerTurn(whitePlayer.id) ? whitePlayer : blackPlayer;
    return isSpectator ? `${playingPlayer.name} ${t("Playing")}` : game.mancalaGame.checkIsPlayerTurn(currentUser.id)
      ? t("YourTurn")
      : t("OpponentTurn");
  }

  return undefined;
}

const InfoPanel: FunctionComponent<{
  context: Context;
  game?: Game;
  currentUser: User;
  whitePlayer: User;
  blackPlayer: User;
  leftPlayer?: User;
  style?: React.CSSProperties;
  visible?: boolean;
  isSpectator?: boolean;
}> = ({
  context,
  game,
  currentUser,
  whitePlayer,
  blackPlayer,
  leftPlayer,
  style,
  visible,
  isSpectator
}) => {

    const { t } = useTranslation();

    if (visible === false) return <></>;
    const isUserTurn = currentUser.id ? game?.mancalaGame.checkIsPlayerTurn(currentUser.id) : false;
    const containerColor = isUserTurn
      ? context.themeManager.theme.playerTurnColor
      : context.themeManager.theme.boardColor;
    const textColor = getColorByBrightness(
      containerColor,
      context.themeManager.theme.textColor,
      context.themeManager.theme.textLightColor
    );
    const text = getInfoPanelTextByGameState({
      context,
      t,
      game,
      currentUser,
      whitePlayer,
      blackPlayer,
      leftPlayer,
      isSpectator
    });
    if (text) {
      return (
        <CircularPanel style={style} color={containerColor}>
          <h4 style={{ margin: "0", color: textColor, transition: 'color 0.5s' }}>
            {text}
          </h4>
        </CircularPanel>
      );
    } else {
      return (<div></div>)
    }
  };

export default InfoPanel;
