import { Theme } from "./Theme";

const greyTheme: Theme = {
  id: "1",
  name: "Grey Theme",
  themePreviewColor: "#4D606E",
  background: "#EEEEEE",
  appBarBgColor: "#e4e4e4",
  textColor: "#4D606E",
  textLightColor: "#EEEEEE",
  playerTurnColor: "#84b8a6",
  boardColor: "#4D606E",
  pitColor: "#D3D4D8",
  pitSelectedColor: "#8837fa",
  stoneColor: "#393E46",
  stoneLightColor: "#EEEEEE",
  pitGameMoveAnimateColor: "#c9b43c",
  pitEmptyPitAnimateColor: "#5d7322",
  pitLastStoneInBankPitAnimateColor: "#9463f7",
  pitGetRivalStonePitAnimateColor: "#ff3d44",
};

export default greyTheme;
