import { Menu, MenuItem } from "@szhsin/react-menu";
import * as React from "react";
import { FunctionComponent } from "react";
import { Context } from "../../context/context";
import "@szhsin/react-menu/dist/index.css";
import "@szhsin/react-menu/dist/transitions/slide.css"

const ThemeSwitchMenu: FunctionComponent<{ context: Context, textColor: string }> = (props) => {
    const { context, textColor } = props;
    const menuButton = <span
        style={{ color: textColor, cursor: 'pointer', userSelect: 'none' }}
        className="material-symbols-outlined">
        light_mode
    </span>;
    const menuItems = context.themeManager.themes.map((theme, index) => {
        return (
            <MenuItem
                key={index}
                style={{ color: textColor }}
                //@ts-ignore
                onMouseOver={(event) => {
                    const htmlElement: HTMLElement = event.target as HTMLElement;
                    if (htmlElement.localName === "li") htmlElement.style.background = "transparent";
                }}
                //@ts-ignore
                onMouseOut={(event) => {
                    const htmlElement: HTMLElement = event.target as HTMLElement;
                    if (htmlElement.localName === "li") htmlElement.style.background = "transparent";
                }}
                onClick={() => (context.themeManager.theme = theme)}>
                <div style={{ background: theme.themePreviewColor }} className="theme-color-circle" />
                {theme.name}
                <style jsx>{`
                .theme-color-circle {
                    border-radius: 5vw;
                    width: 1vw;
                    height: 1vw;
                    margin-right: 1vw;
                }
                `}</style>
            </MenuItem>
        );
    })
    return (
        <div className="menu-container">
            <Menu
                menuStyle={{ background: context.themeManager.theme.appBarBgColor }}
                menuButton={menuButton}
                transition
                align="end">
                {menuItems}
            </Menu>
            <style jsx>{`
                .menu-container {
                    margin: 0 1vh;
                    display: flex;
                    align-items: center;
                }
            `}</style>
        </div>
    );
}

export default ThemeSwitchMenu;