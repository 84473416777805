import { CommonMancalaGame, MancalaGame } from 'mancala.js';
import * as React from 'react';
import { FunctionComponent, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import Center from '../components/Center';
import CircularPanel from '../components/CircularPanel';
import HeaderBar from '../components/headerbar/HeaderBar';
import HeaderbarIcon from '../components/headerbar/HeaderbarIcon';
import HeaderbarTitle from '../components/headerbar/HeaderbarTitle';
import ThemeSwitchMenu from '../components/headerbar/ThemeSwitchMenu';
import PageContainer from '../components/PageContainer';
import Row from '../components/Row';
import { channel_cancel_new_game, channel_on_game_start } from '@mancala/core';
import { Context } from '../context/context';
import { Theme } from '@mancala/core';
import { getColorByBrightness } from "@mancala/core";
import Button from "../components/Button";
import { useTranslation } from 'react-i18next';

const LobyPage: FunctionComponent<{
    context: Context,
    userKey?: string,
    theme: Theme
}> = ({ context, userKey, theme }) => {

    let navigate = useNavigate();

    const { t } = useTranslation();

    const onGameStart = (message: Object) => {
        const newGame: CommonMancalaGame = message as CommonMancalaGame;
        navigate(`/game/${newGame.id}`)
    }

    const onCancelNewGameClick = () => {
        if (context.rtmt.connectionState === "connected") {
            context.rtmt.sendMessage(channel_cancel_new_game, "");
        }
        navigate("/")
    }
    useEffect(() => {
        context.rtmt.addMessageListener(channel_on_game_start, onGameStart);
        context.rtmt.sendMessage("new_game", {});
        return () => {
            context.rtmt.removeMessageListener(channel_on_game_start, onGameStart);
        }
    }, []);
    
    const textColorOnAppBar = getColorByBrightness(
        context.themeManager.theme.appBarBgColor,
        context.themeManager.theme.textColor,
        context.themeManager.theme.textLightColor
    );
    const textColorOnBoard = getColorByBrightness(
        context.themeManager.theme.boardColor,
        context.themeManager.theme.textColor,
        context.themeManager.theme.textLightColor
    );
    return (
        <PageContainer theme={theme!}>
            <HeaderBar color={theme?.appBarBgColor}>
                <Row>
                    <Link style={{ textDecoration: 'none' }} to={"/"}>
                        <HeaderbarIcon />
                    </Link>
                    <Link style={{ textDecoration: 'none' }} to={"/"}>
                        <HeaderbarTitle title={t("Mancala")} color={textColorOnAppBar} />
                    </Link>
                </Row>
                <Row>
                    <ThemeSwitchMenu context={context} textColor={textColorOnAppBar} />
                </Row>
            </HeaderBar>
            <Center>
                <div style={{
                    display: "flex",
                    flexDirection: "column",
                    alignContent: "center"
                }}>
                    <CircularPanel color={context.themeManager.theme.boardColor}>
                        <h4 style={{ margin: "0", color: textColorOnBoard }}>{`${t("SearchingOpponent")} ${t("PleaseWait")}...`}</h4>
                    </CircularPanel>
                    <Button
                        context={context}
                        color={context.themeManager.theme.boardColor}
                        text={t("Cancel")}
                        onClick={onCancelNewGameClick} />
                </div>

            </Center>
        </PageContainer>
    );
}

export default LobyPage;