import * as React from 'react';
import { FunctionComponent } from 'react';
import { Context } from '../context/context';
import { User } from "@mancala/core";
import { getColorByBrightness } from "@mancala/core";
import Space from './Space';
import { useTranslation } from 'react-i18next';

export type LayoutMode = "right" | "left";

const UserStatus: FunctionComponent<{
    context: Context,
    user: User,
    layoutMode: LayoutMode,
    visible?: boolean,
    style?: React.CSSProperties
}> = ({ context, user, layoutMode, visible, style }) => {
    if (visible === false) return <></>;

    const { t } = useTranslation();

    const textColorOnBoard = getColorByBrightness(
        context.themeManager.theme.background,
        context.themeManager.theme.textColor,
        context.themeManager.theme.textLightColor
    );
    return (
        <div style={style} className={layoutMode === "right" ? "flex-rtl" : "flex-ltr"}>
            <span style={{color: textColorOnBoard, transition: 'color 0.5s'}} className='text'>{user.isAnonymous ? t("Anonymous") : user.name}</span>
            <Space width='5px' />
            <div className={"circle " + (user.isOnline ? "online" : "offline")}></div>
            <style jsx>{`
                .online {
                    background-color: ${context.themeManager.theme.boardColor};
                }
                .offline {
                    background-color: transparent;
                }
                .circle {
                    width: 15px;
                    height: 15px;
                    min-width: 15px;
                    min-height: 15px;
                    border-radius: 15px;
                    border: 2px solid ${context.themeManager.theme.boardColor};
                    transition: background-color 0.5s;
                    transition: color 0.5s;
                }
                .flex-rtl {
                    display: flex;
                    flex-direction: row-reverse;
                    align-items: center;
                }
                .flex-ltr {
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                }
                .text {
                    font-weight: bold;
                    text-overflow: ellipsis;
                    overflow: hidden;
                }
                .icon {
                    color : "grey";
                    width: 32px;
                    height: 32px;
                    font-size: 32px;
                }
            `}</style>
        </div>
    );
}

export default UserStatus;