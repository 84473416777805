export type Message = {
    channel: string,
    message: Object,
}

export function encode(message: Message) {
    return JSON.stringify({
        channel : message.channel,
        message : message.message
    });
}

export function decode(bytes: string): Message {
    return JSON.parse(bytes);
}
