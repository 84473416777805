import * as React from "react";
import { FunctionComponent } from "react";
import { Context } from "../context/context";

const FloatingPanel: FunctionComponent<{
  context: Context;
  color: string;
  visible: boolean;
}> = (props) => {
  if(props.visible === false) return <></>
  return (
    <div style={{
      backgroundColor: props.color,
    }}>
      <style jsx>{`
        div {
          position: absolute;
          bottom: 0px;
          left: 0px;
          padding: 15px;
          border-top-right-radius: 1vw;
          min-width: 10vw;
          min-height: 1vw;
          z-index: 100;
        }
      `}</style>
      {props.children}
    </div>
  )
};

export default FloatingPanel;