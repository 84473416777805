export const en = {
    Mancala: "Mancala",
    Leave: "Leave The Game",
    NewGame: "New Game",
    YourTurn: "Your Turn",
    OpponentTurn: "Opponent Turn",
    GameEnded: "Game Ended",
    InternalErrorOccurred: "An internal error has occurred",
    YouWon: "You Won",
    Won: "Won",
    YouLost: "You Lost",
    Connecting: "Connecting",
    Connected: "Connected",
    CannotConnect: "Can't Connect",
    ConnectionLost: "Network Connection Lost",
    ConnectingAgain: "Connecting Again",
    ServerError: "Server Error",
    SearchingOpponet: "Searching Opponet",
    OpponentLeftTheGame: "Opponent Leaves The Game",
    YouLeftTheGame: "You Left The Game",
    UserLeftTheGame: "Left The Game",
    SearchingOpponent: "Searching Opponent",
    PleaseWait: "Please Wait",
    GameDraw: "Draw",
    Anonymous: "Anonymous",
    GameNotFound: "Game Not Found",
    Loading: "Loading",
    Playing: "Playing",
    Error: "Error",
    ErrorWhenRetrievingInformation: "An error occured when retrieving information!",
    UCanOnlyPlayYourOwnPits: "You can only play your own pits",
    UMustWaitUntilCurrentMoveComplete: "You must wait until the current move is complete",
    UCanNotPlayEmptyPit: "You can not play empty pit",
    AreYouSureToLeaveGame: "Are you sure to leave game?",
    Yes: "Yes",
    Cancel: "Cancel",
    Help: "Help",
    WebApp: "Goto WebApp",
    Privacy: "Open Privacy Policy"
}