import { HttpService } from "../service/HttpService"

const user_key = "user_key"

export interface UserKeyStore {
    getUserKey: () => Promise<string>;
}

export class UserKeyStoreImpl implements UserKeyStore {
    private httpService: HttpService;
    private keyStoreHttp: UserKeyStore;
    private keyStoreLocalStorage = new UserKeyStoreLocalStorage()

    constructor(props: { httpService: HttpService }) {
        this.httpService = props.httpService;
        this.keyStoreHttp = new UserKeyStoreLocalHttp({ httpService: this.httpService });
    }

    public async getUserKey(): Promise<string> {
        const maybeUserKey = await this.keyStoreLocalStorage.getUserKey();
        if (maybeUserKey === undefined) {
            const userKey = await this.keyStoreHttp.getUserKey()
            this.keyStoreLocalStorage.storeUserKey(userKey)
            return Promise.resolve(userKey);
        } else {
            return Promise.resolve(maybeUserKey);
        }
    }
}

export class UserKeyStoreLocalHttp implements UserKeyStore {
    httpService: HttpService;

    constructor(params: { httpService: HttpService }) {
        this.httpService = params.httpService;
    }

    public async getUserKey(): Promise<string> {
        const response = await this.httpService.get("/register/")
        return response.text();
    }
}

export class UserKeyStoreLocalStorage {
    public getUserKey(): Promise<string | undefined> {
        const userKey = localStorage.getItem(user_key)
        return Promise.resolve(userKey === null ? undefined : userKey)
    }

    public storeUserKey(userKey: string): void {
        localStorage.setItem(user_key, userKey)
    }
}
