import * as React from "react";
import { FunctionComponent } from "react";
//@ts-ignore
import MancalaIcon from "jsx:../../mancala.svg";

const HeaderbarIcon: FunctionComponent = () => {
    return (
        <div className="header-bar-icon-wrapper">
            <MancalaIcon style={{ height: "30px", width: "30px" }} />
            <style jsx>{`
            .header-bar-icon-wrapper {
                margin-right: 10px;
                display: flex;
                flex-direction: row;
                align-items: center;
            }
        `}</style>
        </div>
    );
}

export default HeaderbarIcon;