import { server } from "../const/config";
import { RTMT, RTMTWS, ThemeManager } from "@mancala/core";
import { HttpServiceImpl } from "../service/HttpService";
import { GameStore, GameStoreImpl } from "../store/GameStore";
import { UserKeyStore, UserKeyStoreImpl } from "../store/KeyStore";
import { LocalStorage } from "../storage";
import { Storage } from '@mancala/core'

export type Context = {
  rtmt: RTMT;
  userKeyStore: UserKeyStore;
  themeManager: ThemeManager;
  gameStore: GameStore;
  storage: Storage;
};

export const initContext = (): Context => {
  const rtmt = new RTMTWS();
  const httpService = new HttpServiceImpl(server.serverAdress);
  const userKeyStore = new UserKeyStoreImpl({ httpService });
  const gameStore = new GameStoreImpl({ httpService });
  const storage = new LocalStorage();
  const themeManager = new ThemeManager(storage);
  return {
    rtmt: rtmt,
    userKeyStore: userKeyStore,
    themeManager,
    gameStore,
    storage
  };
};
