import * as React from "react";
import { FunctionComponent, useEffect, useState } from "react";
import { getColorByBrightness } from "@mancala/core";
import { Theme } from "@mancala/core";
import HeaderBar from "../components/headerbar/HeaderBar";
import PageContainer from "../components/PageContainer";
import Row from "../components/Row";
import HeaderbarIcon from "../components/headerbar/HeaderbarIcon";
import HeaderbarTitle from "../components/headerbar/HeaderbarTitle";
import ThemeSwitchMenu from "../components/headerbar/ThemeSwitchMenu";
import Button from "../components/Button";
import { Context } from "../context/context";
import { Link, useNavigate } from "react-router-dom";
import Util from "../util/Util";
import { useTranslation } from "react-i18next";

const Home: FunctionComponent<{
  context: Context,
  userKey?: string,
  theme: Theme,
}> = ({ context, userKey, theme }) => {

  const navigate = useNavigate();

  const { t } = useTranslation();

  const onNewGameClick = () => {
    if(Util.checkConnectionAndMaybeAlert(context, t)) return;
    navigate("/loby")
  };

  const textColorOnAppBar = getColorByBrightness(
    context.themeManager.theme.appBarBgColor,
    context.themeManager.theme.textColor,
    context.themeManager.theme.textLightColor
  );
  return (
    <PageContainer theme={theme!}>
      <HeaderBar color={theme?.appBarBgColor}>
        <Row>
          <Link style={{ textDecoration: 'none' }} to={"/"}>
            <HeaderbarIcon />
          </Link>
          <Link style={{ textDecoration: 'none' }} to={"/"}>
            <HeaderbarTitle title={t("Mancala")} color={textColorOnAppBar} />
          </Link>
        </Row>
        <Row>
          <ThemeSwitchMenu context={context} textColor={textColorOnAppBar} />
          <Button
            context={context}
            color={context.themeManager.theme.pitColor}
            text={t("NewGame")}
            onClick={onNewGameClick} />
        </Row>
      </HeaderBar>
    </PageContainer>
  );
};

export default Home;
