export const tr = {
    Mancala: "Köçürme",
    Leave: "Oyundan Ayrıl",
    NewGame: "Yeni Oyun",
    YourTurn: "Sıra Sende",
    OpponentTurn: "Sıra Rakipte",
    GameEnded: "Oyun Bitti",
    InternalErrorOccurred: "Dahili bir hata oluştu",
    YouWon: "Kazandın",
    Won: "Kazandı",
    YouLost: "Kaybettin",
    Connecting: "Bağlanılıyor",
    Connected: "Bağlandı",
    CannotConnect: "Bağlanılamadı",
    ConnectionLost: "Ağ Bağlantısı Koptu",
    ConnectingAgain: "Tekrar Bağlanılıyor",
    ServerError: "Sunucu Hatası",
    SearchingOpponet: "Rakip Aranıyor",
    OpponentLeftTheGame: "Rakip Oyundan Ayrıldı",
    YouLeftTheGame: "Sen Oyundan Ayrıldın",
    UserLeftTheGame: "Oyundan Ayrıldı",
    SearchingOpponent: "Rakip Aranıyor",
    PleaseWait: "Lütfen Bekleyin",
    GameDraw: "Berabere",
    Anonymous: "Anonim",
    GameNotFound: "Oyun Bulunamadı",
    Loading: "Yükleniyor",
    Playing: "Oynuyor",
    Error: "Hata",
    ErrorWhenRetrievingInformation: "Bilgiler toplanırken bir hata oluştu!",
    UCanOnlyPlayYourOwnPits: "Sadece sana ait olan kuyular ile oynayabilirsin",
    UMustWaitUntilCurrentMoveComplete: "Devam eden haraketin bitmesini beklemelisin",
    UCanNotPlayEmptyPit: "Boş kuyu ile oynayamazsın",
    AreYouSureToLeaveGame: "Oyundan ayrılmak istediğine emin misin?",
    Yes: "Evet",
    Cancel: "İptal",
    Help: "Yardım",
    WebApp: "Web Uygulamasına Git",
    Privacy: "Gizlilik Politikasını Göster"
};