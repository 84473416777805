import * as React from 'react';
import { FunctionComponent, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import HeaderBar from '../components/headerbar/HeaderBar';
import HeaderbarIcon from '../components/headerbar/HeaderbarIcon';
import HeaderbarTitle from '../components/headerbar/HeaderbarTitle';
import ThemeSwitchMenu from '../components/headerbar/ThemeSwitchMenu';
import PageContainer from '../components/PageContainer';
import Row from '../components/Row';
import { Context } from '../context/context';
import { Theme } from '@mancala/core';
import { getColorByBrightness } from "@mancala/core";
import { useTranslation } from 'react-i18next';

const PrivacyPage: FunctionComponent<{
    context: Context,
    userKey?: string,
    theme: Theme
}> = ({ context, userKey, theme }) => {

    const { t } = useTranslation();

    const textColorOnAppBar = getColorByBrightness(
        context.themeManager.theme.appBarBgColor,
        context.themeManager.theme.textColor,
        context.themeManager.theme.textLightColor
    );
    const textColorOnBoard = getColorByBrightness(
        context.themeManager.theme.background,
        "#000",
        "#aaa"
    );
    return (
        <PageContainer theme={theme!}>
            <HeaderBar color={theme?.appBarBgColor}>
                <Row>
                    <Link style={{ textDecoration: 'none' }} to={"/"}>
                        <HeaderbarIcon />
                    </Link>
                    <Link style={{ textDecoration: 'none' }} to={"/"}>
                        <HeaderbarTitle title={t("Mancala")} color={textColorOnAppBar} />
                    </Link>
                </Row>
                <Row>
                    <ThemeSwitchMenu context={context} textColor={textColorOnAppBar} />
                </Row>
            </HeaderBar>
            <div style={{
                margin: '30px',
                overflowY: 'scroll',
                color: textColorOnBoard
            }}>

                <h2>Privacy Policy</h2>
                <p> The Mancala team and volunteers built the Mancala app as a open source app. This SERVICE is provided by mancala team and volunteers at no cost and is intended
                    for use as is.</p>
                <p>This page is used to inform website visitors regarding our policies with the collection, use, and
                    disclosure of Personal Information if anyone decided to use our Service.</p>

                <p>The terms used in this Privacy Policy have the same meanings as in our Terms and Conditions,
                    which is accessible at Mancala, unless otherwise defined in this Privacy Policy.</p>

                <p><strong>Information Collection and Use</strong></p>
                <p>The application does not collect any user information and data. The application uses a unique key received from the remote server and stored on the client to identify the anonymous user.</p>

                <p><strong>Cookies</strong></p>
                <p>Cookies are files with small amount of data that is commonly used an anonymous unique identifier.
                    These are sent to your browser from the website that you visit and are stored on your devices’s
                    internal memory.</p>

                <p><strong>Security</strong></p>
                <p>We value your trust in providing us your Personal Information, thus we are striving to use
                    commercially acceptable means of protecting it. But remember that no method of transmission over
                    the internet, or method of electronic storage is 100% secure and reliable, and we cannot
                    guarantee its absolute security.</p>

                <p><strong>Changes to This Privacy Policy</strong></p>
                <p>We may update our Privacy Policy from time to time. Thus, you are advised to review this page
                    periodically for any changes. We will notify you of any changes by posting the new Privacy Policy
                    on this page. These changes are effective immediately, after they are posted on this page.</p>

                <p><strong>Contact Us</strong></p>
                <p>If you have any questions or suggestions about our Privacy Policy, do not hesitate to <a href='/contact'>contact
                    us</a>. </p>
            </div>
        </PageContainer>
    );
}

export default PrivacyPage;