import { Context } from "../context/context";
import { ConnectionState } from "@mancala/core";
import notyf from "./Notyf";

export default class Util {
    public static range(size: number) {
        var ans: number[] = [];
        for (let i = 0; i < size; i++) {
            ans.push(i);
        }
        return ans;
    }

    public static checkConnectionAndMaybeAlert(context: Context, t: (text: string) => string): boolean {
        if (context.rtmt.connectionState !== "connected") {
            notyf.error(t("ConnectionLost"));
            return true;
        }
        return false;
    }

    public static getTextByConnectionState(context: Context, connectionState: ConnectionState, t: (text: string) => string): string {
        const map: { [key: string]: string } = {
            connecting: t("Connecting"),
            connected: t("Connected"),
            error: t("CannotConnect"),
            closed: t("ConnectingAgain"),
            reconnecting: t("ConnectingAgain"),
        };
        return map[connectionState];
    }
}
