export class PitViewModel {
  id: string;
  stoneCount: number;
  stoneColor: string;
  pitColor: string;

  constructor(
    id: string,
    stoneCount: number,
    stoneColor: string,
    pitColor: string
  ) {
    this.id = id;
    this.stoneCount = stoneCount;
    this.stoneColor = stoneColor;
    this.pitColor = pitColor;
  }
}
