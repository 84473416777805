import * as React from 'react';
import { FunctionComponent, useState } from 'react';
import {
    BrowserRouter,
    Routes,
    Route,
} from "react-router-dom";
import FloatingPanel from './components/FloatingPanel';
import GamePage from './routes/GamePage';
import Home from './routes/Home';

import { initContext } from './context/context';
import { RTMTWS, ConnectionState } from '@mancala/core';
import { getColorByBrightness } from "@mancala/core";
import { Theme } from '@mancala/core';
import LobyPage from './routes/LobyPage';
import PrivacyPage from './routes/PrivacyPage';
import swal from 'sweetalert';
import Util from './util/Util';
import { server } from './const/config';
import { useTranslation } from 'react-i18next';

const context = initContext();

const MancalaApp: FunctionComponent = () => {

    const { t, i18n } = useTranslation();

    const [userKey, setUserKey] = useState<string | undefined>(undefined);

    const [connectionState, setConnetionState] = useState<ConnectionState>("connecting");

    const [theme, setTheme] = useState<Theme>(context.themeManager.theme);

    const onConnectionError = (event: Event) => console.error("(RTMT) Connection Error: ", event);

    const onConnectionChange = (_connectionState: ConnectionState) => setConnetionState(_connectionState);

    const onThemeChange = (theme: Theme) => setTheme(theme);

    React.useEffect(() => {
        //@ts-ignore
        const deviceLanguage = navigator.language || navigator.userLanguage;
        i18n.changeLanguage(deviceLanguage === "tr-TR" ? "tr" : "en")
      }, []);

    const connectRTMT = (userKey: string) => {
        const rtmt = context.rtmt as RTMTWS;
        rtmt.on("error", onConnectionError);
        rtmt.on("connectionchange", onConnectionChange)
        const url = server.wsServerAdress + "?userKey=" + userKey;
        rtmt.connectWebSocket(url, userKey)
        return rtmt;
    }

    const loadUserKeyAndConnectServer = () => {
        context.userKeyStore.getUserKey().then((userKey: string) => {
            setUserKey(userKey);
            connectRTMT(userKey);
        }).catch((error) => {
            //TODO: check if it is network error!
            swal(t("Error") + "!", t("ErrorWhenRetrievingInformation"), "error");
            console.error(error);
        });
    }

    const disposeApp = () => {
        context.rtmt?.dispose();
        context.themeManager.off("themechange", onThemeChange);
    }

    React.useEffect(() => {
        loadUserKeyAndConnectServer();
        context.themeManager.on("themechange", onThemeChange);
        return () => disposeApp();
    }, []);

    const textColorOnBoard = getColorByBrightness(
        context.themeManager.theme.boardColor,
        context.themeManager.theme.textColor,
        context.themeManager.theme.textLightColor
    );
    return (
        <>
            <BrowserRouter>
                <Routes>
                    <Route index element={<Home context={context} theme={theme} userKey={userKey} />} />
                    <Route path="/" >
                        <Route path="game" >
                            <Route path=":gameId" element={<GamePage context={context} theme={theme} userKey={userKey} />}  ></Route>
                        </Route>
                        <Route path="loby" element={<LobyPage context={context} theme={theme} userKey={userKey} />}>
                        </Route>
                        <Route path="privacy" element={<PrivacyPage context={context} theme={theme} userKey={userKey} />}>
                        </Route>
                    </Route>
                </Routes>
            </BrowserRouter>
            <FloatingPanel context={context} color={context.themeManager.theme.boardColor} visible={connectionState != "connected"}>
                <span style={{ color: textColorOnBoard, transition: 'color 0.5s' }}>{Util.getTextByConnectionState(context, connectionState, t)}</span>
            </FloatingPanel>
        </>
    );
}

export default MancalaApp;