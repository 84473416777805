import { TinyEmitter } from "tiny-emitter"

export type OnMessage = (message: Object) => any

export type ConnectionState = "none" | "connecting" | "error" | "connected" | "closed" | "reconnecting";

export type RtmtEventTypes = "open" | "close" | "connected" | "error" | "disconnected" | "message" | "connectionchange";

export const RTMT_WS_PING_INTERVAL = 1000, RTMT_WS_PING_INTERVAL_BUFFER_TIME = 2000;

export interface RTMT extends TinyEmitter {
    get connectionState(): ConnectionState;
    sendMessage: (channel: string, message: Object) => void;
    addMessageListener(channel: string, callback: (message: any) => void): any;
    removeMessageListener(channel: string, callback: (message: any) => void): any;
    on(event: RtmtEventTypes, callback: (...value: any[]) => void): this;
    off(event: RtmtEventTypes, callback: (...value: any[]) => void): this;
    dispose(): any;
}