import * as React from "react";
import { FunctionComponent } from "react";;

const HeaderBar: FunctionComponent<{ color?: string }> = ({children, color }) => {
    return (
        <div style={{ background: color }} className="header-bar">
            {children}
            <style jsx>{`
            .header-bar {
                padding: 0px 4vw;
                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: space-between;
                align-self: stretch;
                transition: background-color 0.5s;
            }
            `}</style>
        </div>)
}
export default HeaderBar;
