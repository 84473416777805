import * as React from 'react';
import { FunctionComponent } from 'react';

const BoardToolbar: FunctionComponent<{ visible?: boolean, style?: React.CSSProperties }> = ({ children, visible, style }) => {
    if(visible === false) return <></>;
    return (
        <div style={style} className='toolbar'>
            {children}
            <style jsx>{`
                .toolbar {
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    justify-content: space-between;
                    align-self: stretch;
                }
            `}</style>
        </div>
    );
}

export default BoardToolbar;