import { PitViewModel } from "../viewmodel/PitViewModel";

export class PitViewModelFactory {
  public static create(params: {
    id: string;
    stoneCount: number;
    stoneColor: string;
    pitColor: string;
  }): PitViewModel {
    const { id, stoneCount, stoneColor, pitColor } = params;
    return new PitViewModel(id, stoneCount, stoneColor, pitColor);
  }
}
