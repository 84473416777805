import { MancalaGame } from "mancala.js";
import { Game } from "@mancala/core";
import { HttpService } from "../service/HttpService";

export interface GameStore {
    get(id: string): Promise<Game | undefined>;
}

export class GameStoreImpl implements GameStore {
    httpService: HttpService;
    constructor(props: { httpService: HttpService }) {
        this.httpService = props.httpService;
    }

    async get(id: string): Promise<Game | undefined> {
        try {
            const response = await this.httpService.get(`/game/${id}`);
            const json = await response.json();
            const game: Game = json as Game;
            game.mancalaGame = MancalaGame.createFromMancalaGame(game.mancalaGame);
            return game;
        } catch (error) {
            // todo check error
            Promise.resolve(undefined);
        }
    }

}