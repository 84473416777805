export const channel_new_game = "new_game"
export const channel_cancel_new_game = "cancel_new_game"
export const channel_on_game_start = "on_game_start"
export const channel_game_move = "game_move"
export const channel_on_game_update = "on_game_update"
export const channel_leave_game = "leave_game"
export const channel_on_game_end = "on_game_end"
export const channel_on_game_crashed = "on_game_crashed"
export const channel_on_game_user_leave = "on_game_user_leave"
export const channel_ping = "ping"
export const channel_pong = "pong"
export const channel_on_user_connection_change = "channel_on_user_connection_change"
export const channel_listen_game_events = "channel_listen_game_events"
export const channel_unlisten_game_events = "channel_unlisten_game_events"