import * as React from "react";
import { FunctionComponent } from "react";
import Util from "../../util/Util";
import { PitViewModel } from "@mancala/core";
import StoneView from "./StoneView";

const PitView: FunctionComponent<{
    pitViewModel: PitViewModel;
    onClick: () => void;
}> = ({ pitViewModel, onClick }) => {
    const stones = [...Util.range(pitViewModel.stoneCount)].map((i, index) => (
        <StoneView key={index} color={pitViewModel.stoneColor} />
    ));

    return (
        <div className="pit" onClick={onClick} style={{ background: pitViewModel.pitColor }}>
            {stones}
            <style jsx>{`
                .pit {
                    margin: 5px;
                    padding: 5px;
                    border-radius: 10vw;
                    transition: background-color 0.5s;
                    display: flex;
                    align-items: center;
                    align-content: center;
                    justify-content: center;
                    justify-items: center;
                    flex-wrap: wrap;
                }
            `}</style>
        </div>
    );
};

export default PitView;