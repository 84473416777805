import * as React from "react";
import { FunctionComponent } from "react";
import { Context } from "../context/context";
import { getColorByBrightness } from "@mancala/core";

const Button: FunctionComponent<{
  context: Context;
  text: String;
  onClick: () => void;
  color: string;
}> = ({ context, text, color, onClick }) => {
  const textColor = getColorByBrightness(
    color,
    context.themeManager.theme.textColor,
    context.themeManager.theme.textLightColor
  );
  return (
    <button
      onClick={onClick}
      style={{
        backgroundColor: color,
        color: textColor,
      }}
    >
      <style jsx>{`
        button {
          margin: 5px;
          padding: 10px;
          border: none;
          border-radius: 30px;
          transition: color 0.5s; 
          transition: background-color 0.5s; 
          white-space: nowrap;
        }
      `}</style>
      {text}
    </button>
  );
};

export default Button;
