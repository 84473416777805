import * as React from "react";
import { FunctionComponent } from "react";
import { Theme } from "@mancala/core";

const PageContainer: FunctionComponent<{ theme: Theme }> = (props) => {
    return (
        <div style={{
            background: props.theme?.background,
        }}>
            <style jsx>{`
                div {
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    flex: 1;
                    min-height: 400px;
                    transition: background-color 0.5s;
                }
            `}</style>
            {props.children}
        </div>
    );
}

export default PageContainer;