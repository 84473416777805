import { Theme } from "./Theme";

// https://colorhunt.co/palette/525252414141313131ec625f
const colors = {
  primary: "#414141",
  secondary: "#313131",
  tertiary: "#606060",
  quaternary: "#808080",
};

const colorSpecial = "#337a44";

const darkTheme: Theme = {
  id: "2",
  name: "Dark Theme",
  themePreviewColor: colors.primary,
  background: colors.primary,
  appBarBgColor: colors.secondary,
  textColor: colors.primary,
  textLightColor: "#AAAAAA",
  playerTurnColor: colors.tertiary,
  boardColor: colors.secondary,
  pitColor: colors.tertiary,
  pitSelectedColor: colors.secondary,
  stoneColor: "#252525",
  stoneLightColor: "#252525",
  pitGameMoveAnimateColor: colors.quaternary,
  pitEmptyPitAnimateColor: colorSpecial,
  pitLastStoneInBankPitAnimateColor: colorSpecial,
  pitGetRivalStonePitAnimateColor: colorSpecial,
};

export default darkTheme;
