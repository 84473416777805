import * as React from 'react';
import { FunctionComponent } from 'react';

const Row: FunctionComponent = ({children}) => {
    return (
        <div className="row">
            {children}
            <style jsx>{`
            .row {
                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: space-between;
            }
            `}</style>
        </div>
    );
}

export default Row;