import { HttpService } from "@mancala/core"

export class HttpServiceImpl implements HttpService {
  public serverAdress: string;

  constructor(serverAdress: string) {
    this.serverAdress = serverAdress;
  }

  public async get(route: string): Promise<Response> {
    const url = this.serverAdress + route;
    const requestOptions = {
      method: 'GET',
      headers: { 'Content-Type': 'application/json' },
    };
    const response = await fetch(url, requestOptions);
    return response;
  }
}