import * as React from 'react';
import { FunctionComponent } from 'react';

const CircularPanel: FunctionComponent<{
  color: string;
  style?: React.CSSProperties
}> = (props) => {
  return (
    <div style={Object.assign({ background: props.color }, props.style)}>
      <style jsx>{`
            div {
              padding: 10px 20px;
              border-radius: 30px;
              transition: background-color 0.5s;
              white-space: nowrap;
            }
          `}
      </style>
      {props.children}
    </div>
  );
}

export default CircularPanel;
