import * as React from 'react';
import { FunctionComponent } from 'react';
import { Context } from '../context/context';
import { LoadingState } from "@mancala/core";
import { getColorByBrightness } from "@mancala/core";
import CircularPanel from './CircularPanel';
import { useTranslation } from 'react-i18next';

const LoadingComponent: FunctionComponent<{ context: Context, loadingState: LoadingState<any> }> = ({ context, loadingState }) => {

    const { t } = useTranslation();

    if (loadingState.isUnset() || loadingState.isLoaded()) {
        return <></>
    }
    if (loadingState.isLoading() || loadingState.isError()) {
        const textColorOnBoard = getColorByBrightness(
            context.themeManager.theme.boardColor,
            context.themeManager.theme.textColor,
            context.themeManager.theme.textLightColor
        );
        const text = loadingState.isLoading() ? t("Loading") +"..." : loadingState.errorMessage;
        return (
            <CircularPanel color={context.themeManager.theme.boardColor}>
                <h4 style={{ margin: "0", color: textColorOnBoard }}>{`${text}`}</h4>
            </CircularPanel>
        );
    }
    return <></>
}

export default LoadingComponent;