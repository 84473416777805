import * as React from "react";
import { FunctionComponent } from "react";

const StoneView: FunctionComponent<{ color: string }> = ({ color }) => {
  return (
    <div className="stone" style={{ background: color }}>
      <style jsx>{`
          .stone {
            margin: 1px;
            width: 1vw;
            height: 1vw;
            border-radius: 10vw;
            transition: background-color 0.5s;
          }
        `}</style>
    </div>
  );
};

export default StoneView;