// https://medium.com/@yashpalraj/implement-i18n-in-react-native-application-a4c573e2e2a6

import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import { en, tr } from '@mancala/core';
const resources = { // list of languages
    en: {
        translation: en
    },
    tr: {
        translation: tr
    }
};
i18n.use(initReactI18next) // passes i18n down to react-i18next
    .init({
        compatibilityJSON: 'v3', //To make it work for Android devices, add this line.
        resources,
        lng: 'en', // default language to use.
        // if you're using a language detector, do not define the lng option
        interpolation: {
            escapeValue: false,
        },
    });
export default i18n;