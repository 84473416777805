import { Theme } from "./Theme";

const colorSpecial = "#8B8B8B";

const lightTheme: Theme = {
  id: "1",
  name: "Light Theme",
  themePreviewColor: "#9B9B9B",
  background: "#BBBBBB",
  appBarBgColor: "#7B7B7B",
  textColor: "#5B5B5B",
  textLightColor: "#EBEBEB",
  playerTurnColor: "#6B6B6B",
  boardColor: "#9B9B9B",
  pitColor: "#B8B8B8",
  pitSelectedColor: "#9B9B9B",
  stoneColor: "#5B5B5B",
  stoneLightColor: "#3B3B3B",
  pitGameMoveAnimateColor: "#ABABAB",
  pitEmptyPitAnimateColor: colorSpecial,
  pitLastStoneInBankPitAnimateColor: colorSpecial,
  pitGetRivalStonePitAnimateColor: colorSpecial,
};

export default lightTheme;
