import { PitViewModel } from "./PitViewModel";

export class BoardViewModel {
  id: string;
  pits: PitViewModel[];
  constructor(id: string, pits: PitViewModel[]) {
    this.id = id;
    this.pits = pits;
  }
}
