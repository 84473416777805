import {BoardViewModel, PitViewModel } from "../viewmodel";

export class BoardViewModelFactory {
  public static create(
    id: string,
    pitViewModels: PitViewModel[]
  ): BoardViewModel {
    return new BoardViewModel(id, pitViewModels);
  }
}
